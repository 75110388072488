import type { Component } from "solid-js";
import { twMerge } from "tailwind-merge";
import { AvatarSize, type AvatarProps } from "~/components/avatars/types";
import { extractInitials } from "./behavior";
import { getUserAvatarPath } from "~/lib/avatars";
import { photoURL } from "@repo/client";

export const CircularAvatar: Component<AvatarProps> = (props) => {
  return (
    <span
      class={twMerge(
        "m-1 ring-2 ring-white inline-flex items-center justify-center rounded-full bg-gray-500 relative",
        props.class,
      )}
      classList={{
        "w-6 h-6": props.size === AvatarSize.Micro,
        "w-9 h-9": props.size === AvatarSize.Mini,
        "w-12 h-12": props.size === AvatarSize.Default,
      }}
    >
      <span class="absolute text-xs font-medium leading-none text-white">
        {extractInitials(props.fullName ?? "Guest")}
      </span>
      <img
        referrerpolicy="no-referrer"
        class="absolute inline-block rounded-full"
        classList={{
          "w-6 h-6": props.size === AvatarSize.Micro,
          "w-9 h-9": props.size === AvatarSize.Mini,
          "w-12 h-12": props.size === AvatarSize.Default,
        }}
        src={`${getUserAvatarPath(props.userId, props.src) ?? photoURL.DefaultGuestPhotoRelPath}?fallback"}`}
        // @ts-expect-error
        onerror="this.style.display='none'"
        alt={props.fullName || "Avatar"}
      />
    </span>
  );
};
