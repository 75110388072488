import { BrowserStorage } from "@repo/storage";
import { useLocation } from "@solidjs/router";
import type { Component } from "solid-js";
import { StButton } from "~/components/buttons";

type SignUpCTAProps = {
  /**
   * CSS class string to apply to the button.
   */
  class: string;
  /**
   * Tracking behaviour to be used in the onClick handler.
   */
  tracking: () => void;
};

/**
 * SignUpCTA is a tracked call-to-action button that enters the registration flow.
 */
export const SignUpCTA: Component<SignUpCTAProps> = (props) => {
  const location = useLocation();
  return (
    <StButton
      href="/auth/signup"
      class={props.class}
      size="lg"
      onClick={() => {
        props.tracking();
        BrowserStorage.setSignupReferrerUrl(location.pathname);
      }}
    >
      Sign Up
    </StButton>
  );
};
