export enum AvatarSize {
  Default = 0,
  Micro = 1,
  Mini = 2,
}

export type AvatarProps = {
  size: AvatarSize;
  userId?: string;
  src?: string;
  fullName?: string;
  class?: string;
};
